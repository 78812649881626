import * as React from "react";
import PageHead from "../../components/PageHead";
import PermissionPopupForm from "./PermissionPopup";
import WizardNewUser from "../../components/WizardNewUser";

import "../../sass/style.scss";
import SitePopupForm from "./SitePopup";
import { IPageProps } from "../../interfaces/page-props";
import { RouteComponentProps } from "react-router-dom";
import {
  ITariff,
  ICustomerAddRequest,
  ICustomerTariffUpdateRequest,
  ICustomerUpdateRequest,
} from "../../interfaces/customer";
import MySettings, { IAreaCode } from "../../static/settings";
import Loading from "../../components/loading";
import Error from "../../components/error";
import AddNewNumberPopup from "./AddNewNumberPopup";
import AddUserPopup from "./AddUserPopup";
import RequestNumberPortPopup from "./RequestNumberPortPopup";
import CompanyDetailsForm from "./CompanyDetailsForm";
import GreetingForm from "./GreetingForm";
import PermissionsForm from "./PermssionsForm";

import TariffFormA from "./TariffForm";
import SitesForm from "./SitesForm";
import NumbersForm from "./NumbersForm";
import UsersForm from "./UsersForm";
import ReviewForm from "./ReviewForm";
import FinalizeForm from "./FinalizeForm";

import { ISite } from "../../interfaces/site";

import { IAccessGroup } from "../../interfaces/access-group";
import InvoiceForm from "./InvoiceForm";
import UserUploadPopup from "./UserUploadPopup";
import App from "../../App";
import { INewServiceNumberPortNumbersRequest } from "../../interfaces/number";

export interface IDemoPageState {
  //currentSearch: any;
  loading: boolean;
  tariffs: ITariff[];
  error: boolean;
  areaCodes: IAreaCode[];
  accessGroups: IAccessGroup[];
  blankGroup: IAccessGroup;
  tariffBundles: ITariff[];
  domesticPPMTariffs:ITariff[]
  domesticBundleTariffs:ITariff[]
}

interface IX extends RouteComponentProps<any>, IPageProps {}
const set = new MySettings();
export default class DemoPage extends React.Component<IX, IDemoPageState> {
  private _isMounted: boolean = false;
  constructor(props: any) {
    super(props);

    this.state = {
      domesticBundleTariffs:[],
      domesticPPMTariffs:[],
      tariffBundles: [],
      error: false,
      loading: true,
      tariffs: [],
      areaCodes: [],
      accessGroups: [],
      blankGroup: { id: "", name: "", permissions: {} },
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  public componentDidMount = () => {
    this._isMounted = true;
    this.get();
  };

  public setARef = (thing: string, element: any) => {
    if (thing === "perm") this.perm = element;
    if (thing === "site") this.site = element;
    if (thing === "addNumber") this.addNumber = element;
    if (thing === "addUser") this.addUser = element;
    if (thing === "numberPort") this.numberPort = element;
    if (thing === "userUpload") this.userUpload = element;
  };

  public setWizRef = (element: WizardNewUser) => {
    this.wizard = element;
  };

  perm: PermissionPopupForm = new PermissionPopupForm({});
  site: SitePopupForm = new SitePopupForm({});
  wizard: WizardNewUser = new WizardNewUser({});
  addNumber: AddNewNumberPopup = new AddNewNumberPopup({});
  addUser: AddUserPopup = new AddUserPopup({});
  numberPort: RequestNumberPortPopup = new RequestNumberPortPopup({});
  userUpload: UserUploadPopup = new UserUploadPopup({});

  public onSubmit = (payload: any) => {
    console.log("onSubmit", payload);
  };
  public onSave = async (payload: any, DONE: any) => {
    this.wizard.setState({ loading: true });

    // ok so step one make the customer
    const custReq: ICustomerAddRequest = {
      customer: {
        name: payload.companyName,
        website: payload.companyWebsite,
        voipServiceType:payload.voipServiceType
      },
      contact: {
        title: "",
        firstName: "",
        lastName: "",
        telephone: "",
        emailAddress: "",
      },
      site: {
        line1: "",
        line2: "",
        city: "",
        postCode: "",
        county: "",
      },
    };
    payload.errors=[]
    try{
      const newCustResp = await set.resellerCustomerAdd(custReq)
      const customerId = newCustResp.id;
      payload.customerId = customerId //so we navigate to the correct customer at the end of the wizard
      payload.mainError = false;
      payload.error = "";

      let cata: ICustomerUpdateRequest = {
        id: customerId,
        invoicesalutation: payload.invTitle,
        invoicefirstName: payload.invFirstName,
        invoicelastName: payload.invLastName,
        invoiceemailAddress: payload.invEmail,
        invoicetelephoneNumber: payload.invPhone,
        invoiceaddressLine1: payload.invLine1,
        invoiceaddressLine2: payload.invLine2,
        invoicecity: payload.invCity,
        invoicecounty: payload.invCounty,
        invoicepostCode: payload.invPostCode,

        generalemailAddress: payload.companyEmail,
        generalfirstName: payload.firstName,
        generallastName: payload.lastName,
        generaltelephoneNumber: payload.companyPhone,
        generalsalutation: payload.title,
      };
      await set.resellerCustomerUpdate(cata)

      let req: ICustomerTariffUpdateRequest = {
        id: customerId,
        tariff: payload.tariff,
        tariffType: payload.tariffType,
        numExtraBundles: payload.numExtraBundles,
        bundleNumberOverride: payload.bundleNumberOverride,
        bundleSizeId: payload.bundleSizeId,
        voipServiceType:payload.voipServiceType
      };
      await set.resellerCustomerTariffUpdate(req)

      for(let i=0;i<payload.permissions.length;i++){
        let accessGroup=payload.permissions[i];
        const xpayload: any = { ...accessGroup };
        xpayload.customersId = customerId;
        const dd=await set.portalAccessGroupsAdd(xpayload)
        payload.permissions[i].id=dd.id
      }

      for(let i=0;i<payload.sites.length;i++){
        let value=payload.sites[i]
        const siteReq: ISite = {
          connectwiseManageCompaniesId: "",
          connectwiseManageAgreementsId: "",
          id: "",
          customersId: customerId,
          description: value.description,
          line1: value.line1,
          line2: value.line2,
          city: value.city,
          postCode: value.postCode,
          county: value.county,
        };
        let newSite=await set.sitesAdd(siteReq)
        payload.sites[i].id = newSite.id;
      }

      for(let i=0;i<payload.users.length;i++){
        let user=payload.users[i]
        let userData: any = {
          ...user,
          id: "",
          usesMFA: false,
        };
        userData.customersId = customerId;
        userData.portalAccessGroups = [];
        if(payload.permissions[userData.permissionIndex]){
          userData.portalAccessGroups.push(payload.permissions[userData.permissionIndex].id)
        }
        let newPerson=await set.addPerson(userData)
        payload.users[i].id = newPerson.id;
      }

      for(let numbersIndex=0;numbersIndex<payload.numbers.length;numbersIndex++){
        let nvalue=payload.numbers[numbersIndex]
        let numbersSite=payload.sites[nvalue.siteIndex]
        
        if(numbersSite.voipServiceAdded!==true){
          let service: any = {
            customersSitesId:numbersSite.id,
            estimatedNumberOfExtensions: 0,
            notes: "",
            type:payload.voipServiceType
          };
          const serv=await set.resellerVoipServiceAdd(service,true)
          numbersSite.voipServiceAdded=serv.success
        }

        if (nvalue.numberType !== "Number Port") {
          let a: any = {
            customersId: customerId,
            customersSitesId:payload.sites[nvalue.siteIndex].id,
            number: nvalue.number,
          };
          await set.resellerNumbersAllocation([a],false)
        } else {
          let a: INewServiceNumberPortNumbersRequest = {
            customersSitesId: payload.sites[nvalue.siteIndex].id,
            numberPortTemplate: nvalue.numberPortTemplate,
            numberPortNumbers: [nvalue.number],
            multiLineNumber: payload.multiLineNumber,
            notes: ""
          };
          await set.resellerNumberPortAdd(a)
        }
      }
    }catch(err2){
      let err = err2 as any
      if(err && err.data && typeof err.data==='string'){
        payload.errors.push({
          error: "Problem Creating Customer",
          e: err.data,
        });
      }
    }
    DONE(payload);
    this.wizard.setState({ loading: false });
  };

  public onSubFormSubmit = (task: any, payload: any) => {
    console.log("onSubFormSubmit", task, payload);
    let update: any = { ...this.wizard.state.values };
    this.wizard.setState({ loading: true });
    setTimeout(() => {
      if (task === "newUser") {
        if (payload.index !== undefined) {
          update.users[payload.index] = payload;
        } else {
          update.users.push(payload);
        }
      }

      if (task === "newPermission") {
        if (payload.index !== undefined) {
          update.permissions[payload.index] = payload;
        } else {
          update.permissions.push(payload);
        }
      }

      if (task === "newSite") {
        if (payload.index !== undefined) {
          update.sites[payload.index] = payload;
        } else {
          update.sites.push(payload);
        }
      }

      if (task === "newNumberPort") {
        const numbers = payload.numbersToPort.split("\n");
        for (let index = 0; index < numbers.length; index++) {
          const element = numbers[index];
          let numb: any = {
            number: element,
            doc: "",
            newFile: payload.newFile,
            multiline: payload.multilineNumber,
            numberPortTemplate: payload.numberPortTemplate,
          };

          numb.numberType = "Number Port";
          update.numbers.push(numb);
        }
      }

      if (task === "uploadedUsers") {
        for (let index = 0; index < payload.users.length; index++) {
          const element = payload.users[index];

          update.users.push(element);
        }
      }

      if (task === "newNumber") {
        for (let index = 0; index < payload.length; index++) {
          const element = payload[index];
          let numb: any = { ...element };

          numb.numberType = "New Number";
          numb.siteIndex = 0;
          //  numb.numberType = 'Number Port';
          update.numbers.push(numb);
        }
      }
      this.wizard.setState({ values: update, loading: false });
    }, 1000);
  };

  public get = async () => {
    if(this._isMounted){
      try{
        const stateUpdate:IDemoPageState = {...this.state};
        stateUpdate.areaCodes=(await set.areaCodesGet()).areaCodes
        stateUpdate.tariffBundles=(await set.resellerTariffBundles('',false)).items
        stateUpdate.tariffs=(await set.resellerCustomerTariffs('',false)).items
        stateUpdate.domesticPPMTariffs=(await set.resellerCustomerTariffs('',true)).items
        stateUpdate.domesticBundleTariffs=(await set.resellerTariffBundles('',true)).items
        
        stateUpdate.loading = false;

        // access groups with blank id
        let gRes=await set.resellerDefaultCustomerPortalAccessGroupsGet()
        stateUpdate.accessGroups = gRes.items.map((rrr) => {
          rrr.id = "";
          return rrr;
        });

        //base accessgroup
        if (stateUpdate.accessGroups.length >= 1) {
          stateUpdate.blankGroup = {
            ...this.state.blankGroup,
          };
          stateUpdate.blankGroup.permissions =
            stateUpdate.accessGroups[0].permissions;
        }
        this._isMounted && this.setState(stateUpdate);
      }catch(err){
        App.newNotificationError("Failed to load the tariffs. Please reload the page and try again." + err);
      }
    }//if(this._isMounted){
  };
  public render() {
    let tariff;
    if (this.state.loading === false) {
      if (this.state.tariffs.length > 0) {
        //     tariff = this.state.tariffs[0].id;
      }
    }

    const data: any = {
      manualAddress: false,
      description: "Main",
      sites: [
        {
          description: "Main",
          line1: "",
          line2: "",
          city: "",
          postCode: "",
          county: "",
        },
      ],
      voipServiceType:'Business',
      tariffType: "PPM",
      numbers: [],
      errors: [],
      users: [
        {
          emailAddress: "",
          firstName: "",
          lastName: "",
          permissionIndex: "",
          salutation: "",
          siteIndex: "",
          telephone: "",
        },
      ],
      permissions: [...this.state.accessGroups],
      tariff: tariff,
    };

    return (
      <div className="content">
        <PageHead
          resellerID={this.props.resellerID}
          title="New Customer"
          parents={[]}
        ></PageHead>

        {this.state.loading === true ? (
          <Loading />
        ) : (
          <>
            {this.state.error === true ? (
              <Error />
            ) : (
              <>
                <PermissionPopupForm
                  ref={(c: PermissionPopupForm) => this.setARef("perm", c)}
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                  default={this.state.blankGroup}
                />
                <SitePopupForm
                  ref={(c: SitePopupForm) => this.setARef("site", c)}
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                />
                <AddNewNumberPopup
                  ref={(c: AddNewNumberPopup) => this.setARef("addNumber", c)}
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                  areaCodes={this.state.areaCodes}
                />
                <AddUserPopup
                  ref={(c: AddUserPopup) => this.setARef("addUser", c)}
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                />
                <RequestNumberPortPopup
                  ref={(c: RequestNumberPortPopup) =>
                    this.setARef("numberPort", c)
                  }
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                />
                <UserUploadPopup
                  ref={(c: UserUploadPopup) => this.setARef("userUpload", c)}
                  data={{}}
                  onSubmit={this.onSubFormSubmit}
                />

                <WizardNewUser
                  ref={(c: WizardNewUser) => this.setWizRef(c)}
                  initialValues={data}
                  onSubmit={this.onSubmit}
                  tariffs={this.state.tariffs}
                >
                  <WizardNewUser.Page
                    t={1}
                    validate={(values: any) => {
                      const errors: any = {};
                      return errors;
                    }}
                    render={GreetingForm}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={2}
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={CompanyDetailsForm}
                    onSubmit={(values: any, next: any) => {
                      //  console.log(values);
                      let goto: any = undefined;

                      values.sites[0].description = values.description;
                      values.sites[0].line1 = values.line1;
                      values.sites[0].line2 = values.line2;
                      values.sites[0].city = values.city;
                      values.sites[0].county = values.county;
                      values.sites[0].postCode = values.postCode;

                      values.users[0].emailAddress = values.companyEmail;
                      values.users[0].firstName = values.firstName;
                      values.users[0].lastName = values.lastName;
                      values.users[0].permissionIndex = "0";
                      values.users[0].salutation = values.title;
                      values.users[0].siteIndex = "0";
                      values.users[0].telephone = values.companyPhone;
                      values.users[0].portalAccess = true;

                      values.invTitle = values.title;
                      values.invFirstName = values.firstName;
                      values.invLastName = values.lastName;
                      values.invEmail = values.companyEmail;
                      values.invPhone = values.companyPhone;
                      values.invLine1 = values.line1;
                      values.invLine2 = values.line2;
                      values.invCity = values.city;
                      values.invCounty = values.county;
                      values.invPostCode = values.postCode;
                      //set this to true so that address is already displayed
                      values.invManualAddress = true;

                      console.log(values);
                      next(values, goto);
                    }}
                  />

                  <WizardNewUser.Page
                    n={3}
                    nt="Invoice"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}like
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return InvoiceForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={4}
                    nt="Permissions"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return PermissionsForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      console.log("VALUES", values);
                      let goto: any = undefined;
                      /*   values.sites[0].description = values.description;
                     values.sites[0].line1 = values.line1;
                     values.sites[0].line2 = values.line2;
                     values.sites[0].city = values.city;
                     values.sites[0].county= values.county;
                     console.log(values);(*/
                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={5}
                    nt="Tariff"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}like
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form:any) => {
                      return TariffFormA(
                        values,
                        this.state,
                        form
                      );
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      values.tariffName='Standard Retail Tariff'
                      if(values.tariffType==='bundle'){
                        for(let i=0;i<this.state.tariffBundles.length;i++){
                          const tariff=this.state.tariffBundles[i]
                          if(tariff.id===values.bundleSizeId){
                            values.tariffName=tariff.name
                          }
                        }
                        for(let i=0;i<this.state.domesticBundleTariffs.length;i++){
                          const tariff=this.state.domesticBundleTariffs[i]
                          if(tariff.id===values.bundleSizeId){
                            values.tariffName=tariff.name
                          }
                        }
                      }else{
                        for(let i=0;i<this.state.tariffs.length;i++){
                          const tariff=this.state.tariffs[i]
                          if(tariff.id===values.tariff){
                            values.tariffName=tariff.name
                          }
                        }
                        for(let i=0;i<this.state.domesticPPMTariffs.length;i++){
                          const tariff=this.state.domesticPPMTariffs[i]
                          if(tariff.id===values.tariff){
                            values.tariffName=tariff.name
                          }
                        }
                      }

                      next(values, goto);
                    }}
                  />

                  <WizardNewUser.Page
                    n={6}
                    nt="Sites"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return SitesForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={7}
                    nt="Numbers"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return NumbersForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;
                      if(values && values.numbers){
                        for(var i=0;i<values.numbers.length;i++){
                          if(values.numbers[i].siteIndex==null){
                            values.numbers[i].siteIndex=0;
                          }
                        }
                      }
                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={8}
                    nt="Users"
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return UsersForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />

                  <WizardNewUser.Page
                    n={9}
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return ReviewForm(
                        values
                      );
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;
                      this.onSave(values, (values: any) => {
                        if(values.errors && values.errors.length>0){
                          for(let i=0;i<values.errors.length;i++){
                            App.newNotificationError(values.errors[i].e)
                          }
                        }else{
                          next(values, goto);
                        }
                      });
                    }}
                  />

                  <WizardNewUser.Page
                    n={10}
                    validate={(values: any) => {
                      const errors: any = {};
                      //  if (!values.service) {
                      //  errors.service = "Required";
                      //}
                      return errors;
                    }}
                    render={(values: any, push: any, pop: any, form: any) => {
                      return FinalizeForm(values, push, pop, form, this);
                    }}
                    onSubmit={(values: any, next: any) => {
                      // console.log(values);
                      let goto: any = undefined;

                      next(values, goto);
                      //  values.moded = 'dfssdfsd'
                    }}
                  />
                </WizardNewUser>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
