import React from "react";

import arrayMutators from "final-form-arrays";
import { Button, Card } from "react-bootstrap";
import { Field, Form } from "react-final-form";

import VipTextFinalField from "../../components/misc/form/VipTextFinalField";
import { IExtension } from "../../interfaces/extension";
import { IVoipService } from "../../interfaces/interfaces";
import MuiCheckboxList from "../../components/MuiComponents/MuiCheckboxList";
interface CallRecordingFormProps {
  data: IVoipService;
  extensions: IExtension[];
  onSubmit: (data: IVoipService) => void;
  goBack: () => void;
}

const CallRecordingForm: React.FC<CallRecordingFormProps> = ({
  data,
  extensions,
  onSubmit,
  history,
  goBack,
}: any) => {

  const extensionsToExclude=extensions
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((extension: any) => ({
      label: extension.callerid,
      value: extension.id,
    }))

  const extensionsToInclude=extensions.sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((extension: any) => ({
      label: extension.callerid,
      value: extension.name,
    }))

  //filter out any extensions that are no longer part of the system
  //as they may cause problems
  if(data && data.callRecordingIncludedExtensions){
    for(let i=0;i<data.callRecordingIncludedExtensions.length;i++){
      const extensionNumber=data.callRecordingIncludedExtensions[i]
      let keep=false
      for(const extension of extensions){
        if(extension.name===extensionNumber){
          keep=true
          break
        }
      }
      if(!keep){
        data.callRecordingIncludedExtensions.splice(i,1)
        i--
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      mutators={{
        ...arrayMutators, setFormAttribute:([fieldName,fieldValue],state,{changeValue})=>{changeValue(state,fieldName,()=>fieldValue)}
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        form: {
          mutators: { push, pop, remove, setFormAttribute },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <Card.Header>
              <h2 className="my-auto">Edit Call Recording Settings</h2>
            </Card.Header>

            <Card.Body>
              <div className="mb-3">
                <label>
                  How many months of recordings does the customer want?
                </label>
                <VipTextFinalField
                  fieldName="vaultSize"
                  placeholder="Months"
                  required={true}
                  type="number"
                />
              </div>

              <div>
                <label>Default Search in Days</label>
                <VipTextFinalField
                  fieldName="defaultSearchInDays"
                  placeholder=""
                  required={true}
                  type="number"
                />
              </div>

              <div className="mb-3">
                <label>
                  What is the maximum number of teams call recording licenses
                  available to this customer?
                </label>
                <VipTextFinalField
                  fieldName="teamsCallRecordingLicenseLimit"
                  placeholder=""
                  required={true}
                  type="number"
                />
              </div>

              <div className="mb-3">
                <label>Email addresses to send call recordings to.</label>
                {/* <VipStringList
                  field="callRecordingEmailAddresses"
                  value={data.callRecordingEmailAddresses}
                  updateField={(fieldName, newCallRecordingEmailAddresses) => {
                    values[fieldName] = newCallRecordingEmailAddresses;
                  }}
                  text="Email"
                  placeholder="Email addresses to send call recordings to."
                  targetField="name"
                  labelKey="name"
                  // options={extensions}
                  // sortFunction={extensionSortFunction}
                /> */}
                <div
                  style={{
                    display: "flex",
                    width: "500px",
                  }}
                >
                  <table
                    style={{
                      width: "500px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <th>Email Address</th>
                        <th>DDI Filter</th>
                        <th></th>
                      </tr>
                      {values.callRecordingEmailAddresses && (
                        <>
                          {values.callRecordingEmailAddresses.map(
                            (email: any, index: any) => (
                              <tr key={index}>
                                <td>{email.emailAddress}</td>
                                <td>{email.ddiFilter}</td>
                                <td>
                                  <Button
                                    onClick={() =>
                                      {
                                        if(index===0 && values.callRecordingEmailAddresses.length===1){
                                          //we have to do it this way because there is a bug
                                          //in final form that always leaves the last element there
                                          //and never clears it
                                          setFormAttribute('callRecordingEmailAddresses',[])
                                        }else{
                                          remove(
                                            "callRecordingEmailAddresses",
                                            index
                                          )
                                        }
                                      
                                      }
                                    }
                                    className="float-right"
                                    variant="danger"
                                    type="submit"
                                    disabled={submitting}
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ margin: "10px" }}>
                  <label>Email</label>
                  <VipTextFinalField
                    fieldName="emailAddress"
                    placeholder="Email Address"
                    required={false}
                    type="text"
                  />
                </div>
                <div style={{ margin: "10px" }}>
                  <label>DDI Filter</label>
                  <VipTextFinalField
                    fieldName="ddiFilter"
                    placeholder="DDI Filter"
                    required={false}
                    type="text"
                  />
                </div>
                <div style={{ margin: "10px", marginTop: "20px" }}>
                  <Button
                    className="float-left noleft"
                    variant="primary"
                    onClick={() => {
                      push("callRecordingEmailAddresses", {
                        emailAddress: values.emailAddress,
                        ddiFilter: values.ddiFilter,
                      });
                      remove("emailAddress", 0);
                      remove("ddiFilter", 0);
                    }}
                  >
                    Add Email
                  </Button>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-check-label">
                  <Field
                    name="vaultGrowsIndefinitely"
                    component="input"
                    type="checkbox"
                  />{" "}
                  Keep recordings forever?
                </label>
              </div>

              <div className="mb-3">
                <label className="form-check-label">
                  <Field
                    name="callRecordingTranscriptionEnabled"
                    component="input"
                    type="checkbox"
                    disabled={true}
                  />{" "}
                  Enable transcription of call recordings? (This feature is no longer available. Please contact VIP VoIP if you require it.)
                </label>
              </div>

              <div className="mb-3">
                <label>
                  Extension types to show on call recording search form:{" "}
                </label>
                <Field
                  component="select"
                  name="extensionTypesToShowOnCallRecordingSearch"
                >
                  <option value="All">All</option>
                  <option value="Hot Desks">Hot Desks</option>
                </Field>
              </div>

              <div className="mb-3">
                <label className="form-check-label">
                  <Field
                    name="recordInternal"
                    component="input"
                    type="checkbox"
                  />{" "}
                  Record internal calls
                </label>
              </div>

              <div className="mb-3">
                <label className="form-check-label">
                  <Field name="separateAPI" component="input" type="checkbox" />{" "}
                  Keep a separate copy for API users
                </label>
              </div>

              <div className="mb-3">
                <label>Included Extensions (leave unticked for all):</label>
                <MuiCheckboxList
                  fieldName="callRecordingIncludedExtensions"
                  data={extensionsToInclude}
                />
              </div>

              <div className="mb-3">
                <label>Excluded Extensions:</label>

                <MuiCheckboxList
                  fieldName="excludedExtensions"
                  data={extensionsToExclude}
                />
              </div>

              <h5>How do you want to pay for the recordings?</h5>
              <div className="mb-3">
                <label>
                  <Field
                    name="measureSizeBy"
                    component="input"
                    type="radio"
                    value="months"
                  />{" "}
                  Per user per month (Cheaper for smaller customers)
                </label>

                <div>
                  <label>
                    <Field
                      name="measureSizeBy"
                      component="input"
                      type="radio"
                      value="gigabytes"
                    />{" "}
                    Per 100 gigabytes (Cheaper for larger customers)
                  </label>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <Button
                className="float-right"
                variant="primary"
                type="button"
                disabled={submitting}
                onClick={handleSubmit}
              >
                Save
              </Button>

              <Button
                className="float-left noleft"
                variant="primary"
                onClick={goBack}
                disabled={submitting}
              >
                Back
              </Button>
            </Card.Footer>
          </Card>
        </form>
      )}
    />
  );
};

export default CallRecordingForm;
