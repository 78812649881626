export interface IProvisioningIDResponse {
  id: string;
}

export enum ProvisioningSettingEnabledDirectories {
  "LDAP" = "LDAP",
  "History" = "History",
  "Remote Phone Book" = "Remote Phone Book",
  "Blacklist" = "Blacklist",
  "Local Directory" = "Local Directory",
}

export enum ProvisioningSettingLDAPVersion {
  THREE = "3",
  TWO = "2",
}

export enum ProvisioningSettingLocalTimeZone {
  "+0" = "0",
  "11-" = "-11",
  "10-" = "-10",
  "9-" = "-9",
  "8-" = "-8",
  "7-" = "-7",
  "6-" = "-6",
  "5-" = "-5",
  "4-" = "-4",
  "3-" = "-3",
  "2-" = "-2",
  "1-" = "-1",
  "+1" = "+1",
  "+2" = "+2",
  "+3" = "+3",
  "+4" = "+4",
  "+5" = "+5",
  "+6" = "+6",
  "+7" = "+7",
  "+8" = "+8",
  "+9" = "+9",
  "+10" = "+10",
  "+11" = "+11",
  "+12" = "+12",
  "+13" = "+13",
}

export enum ProvisioningSettingKeyType {
  "N/A" = "0",
  "Forward" = "2",
  "DND" = "5",
  "Call Return" = "7",
  "SMS" = "8",
  "Directed Pickup" = "9",
  "Speed Dial" = "13",
  "XML Group" = "22",
  "Group Pickup" = "23",
  "XML Browser" = "27",
  "History" = "28",
  "Menu" = "30",
  "Switch Account" = "31",
  "New SMS" = "32",
  "Status" = "33",
  "LDAP" = "38",
}

export enum ProvisioningSettingPhoneLanguage {
  "English" = "English",
  "French" = "French",
  "German" = "German",
  "Italian" = "Italian",
  "Polish" = "Polish",
  "Portuguese" = "Portuguese",
  "Spanish" = "Spanish",
  "Turkish" = "Turkish",
  "Russian" = "Russian",
}

export enum ProvisioningSettingsDesktopAppExtensionsToShow{
  "All" = "All",
  "Hot Desk Users Only" = "Hot Desk Users Only"
}

export enum ProvisioningSettingTransportMethod {
  UDP = "UDP",
  TLS = "TLS",
}

export enum ProvisioningSettingsCustomFieldType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
}

export enum ProvisioningSettingsCustomFieldDevice {
  YEALINK = "Yealink",
}

export interface IProvisioningSettingsCustomField {
  isNew?: boolean;
  name: string;
  label: string;
  type: ProvisioningSettingsCustomFieldType;
  value: any;
  overridden: boolean;
  device: ProvisioningSettingsCustomFieldDevice;
}

export interface IExtraAccount {
  asteriskServerExtensionsId:string
  customersSitesId:string
  customersId:string
}

export interface IYealinkFirmwares {
  uuid:string;
  userAgentPrefix:string
  url:string
}

// TODO is there a way we can just keep adding to an array all these new fields and then programmatically generate all the visible widgets in the form with a loop?
// "actionUrlSetupCompleted","actionUrlRegistered","actionUrlUnregistered","actionUrlRegisterFailed","actionUrlOffHook","actionUrlOnHook","actionUrlIncomingCall","actionUrlOutgoingCall","actionUrlCallEstablished","actionUrlDndOn","actionUrlDndOff","actionUrlAlwaysFwdOn","actionUrlAlwaysFwdOff","actionUrlBusyFwdOn","actionUrlBusyFwdOff","actionUrlNoAnswerFwdOn","actionUrlNoAnswerFwdOff","actionUrlTransferCall","actionUrlBlindTransferCall","actionUrlAttendedTransferCall","actionUrlHold","actionUrlUnhold","actionUrlHeld","actionUrlUnheld","actionUrlMute","actionUrlUnmute","actionUrlMissedCall","actionUrlCallTerminated","actionUrlBusyToIdle","actionUrlIdleToBusy","actionUrlIpChange","actionUrlForwardIncomingCall","actionUrlRejectIncomingCall","actionUrlAnswerNewIncomingCall","actionUrlTransferFinished","actionUrlTransferFailed","actionUrlSetupAutopFinish","actionUrlCallWaitingOn","actionUrlCallWaitingOff","actionUrlHeadset","actionUrlHandfree","actionUrlCancelCallout","actionUrlRemoteBusy","actionUrlCallRemoteCanceled","actionUrlPeripheralInformation","actionUrlVpnIp"
export interface IProvisioningSettings {
  yealinkFirmwares: IYealinkFirmwares[]
  extraAccounts: IExtraAccount[];
  customFields: IProvisioningSettingsCustomField[];
  account1DisplayName?: string; // the display name that appears on the phone
  account1Label?: string; // the label that appears on the phone for this account
  account1MissedCallLog?: boolean; // do we show the
  account2DisplayName?: string; // the display name for this account
  account2Label?: string; // the label that appears on the phone
  account2MissedCallLog?: boolean; // does this account use the missed call log on the phone
  account2Srtp?: boolean; // does this account use encrypted audio
  account2TransportMethod?: ProvisioningSettingTransportMethod; // the protocal this account uses
  customStatusesJson?: string; // json for custom statuses
  enabledDirectories?: ProvisioningSettingEnabledDirectories[]; // Enabled Directories
  featuresSpeakerModeEnable?: boolean; // Speaker Mode Enabled
  featuresVoiceMailPopupEnable?: boolean; // Voice Mail Pop Up Notifications
  ldapBase?: string; // base location for ldap
  ldapCallInLookup?: boolean; // LDAP Look Up Incoming Calls
  ldapDisplayName?: string; // name for the ldap account
  ldapEnable?: boolean; // enable the ldap phone numbers directory
  ldapHost?: string; // hostname for the ldap server
  ldapMaxHits?: string; // max results for ldap
  ldapNameAttributes?: string; // attributes to bring back
  ldapNameFilter?: string; // the filter for ldap results name
  ldapNumberAttributes?: string; // number attributes
  ldapNumberFilter?: string; // the filter on ldap results number
  ldapPassword?: string; // password for ldap
  ldapPort?: string; // port number for the ldap server
  ldapSort?: boolean; // LDAP Alphabetical Sort
  ldapUser?: string; // username for ldap
  ldapVersion?: ProvisioningSettingLDAPVersion; // ldap protocol version
  localTimeTimeZone?: ProvisioningSettingLocalTimeZone; // time zone adjustment
  networkDhcpHostName?: string; // DHCP Host Name
  phoneLanguage?: ProvisioningSettingPhoneLanguage; // Phone Language
  phoneSettingMissedCallPowerLedFlashEnable?: boolean; // Power Light Flash After Missed Call
  programmablekey3Type?: ProvisioningSettingKeyType; // Programmable Key 3 Type
  srtp?: boolean; // does this phone use encrypted audio
  staticSecurityAdminPassword?: string; // Admin Panel Password
  staticSecurityUserPassword?:string //the user password for the admin panel
  transportMethod?: ProvisioningSettingTransportMethod; // what protocol the phone uses to talk to the server
  // useCallerIdAsLabel?:boolean // not available on individual phones
  wuiHttpEnable?: boolean; // HTTP Admin Panel Enabled
  wuiHttpsEnable?: boolean; // HTTPS Admin Panel Enabled
  // yealinkTemplateVersion?:string // not available to customers
  desktopAppAutomaticUpdatesDisabled?: boolean; // Tells the desktop app to stop automatically updating
}

export interface IProvisioningRecord {
  id: string;
  uuid: string;
  userAgent: string;
  provisioningId: string;
  extensionName: string;
  extensionCallerid: string;
  asteriskServerExtensionsId: string;
  asteriskServerExtensionsId2: string;
  provisioningSettings?: IProvisioningSettings;
  customersId?: string;
  lastTemplateDeliveredDateTime?:string;
  lastTemplateDelivered?:string;
}

export interface IProvisioningGetHttpRequest {
  uuid?: string; //can be left empty
  name?: string; //can be left empty
  customersId?: string;
}

export interface IProvisioningGetHttpResponse {
  items: IProvisioningRecord[];
}

export interface ProvisioningPostSettingRequest {
  id: string;
  provisioningSettings: IProvisioningRecord;
}

export interface IProvisionedDevice {
  id: string;
  uuid: string;
  customersId: string;
  customersSitesId: string;
  asteriskServerExtensionsId: string;
  customersSitesId2: string;
  asteriskServerExtensionsId2: string;
  deskPhoneId: string;
  provisioningSettings: IProvisioningSettings;
  userAgent: string;
  extensionName: string;
  extensionCallerid: string;
  provisioningId: string;
}

export interface IProvisionedDeviceResponse {
  extensionNumber: string; // the number of the extension they paired with
  item: IProvisionedDevice; // the actual provsioning record
}
