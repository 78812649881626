export interface IEmailReportsHttpRequest {
  customersId?:string
  customersSitesServicesId?: string;
}

export enum EmailReportType {
  "Agent Summary" = "Agent Summary",
  "Auto Attendant Options" = "Auto Attendant Options",
  "Call Logs" = "Call Logs",
  "Call Paths" = "Call Paths",
  "Call Recordings" = "Call Recordings",
  "Custom Presence" = "Custom Presence",
  "DND Changes" = "DND Changes",
  "Hot Desk Logins" = "Hot Desk Logins",
  "Lost Calls" = "Lost Calls",
  "Number Trackers" = "Number Trackers",
  "Per DDI Per Hour" = "Per DDI Per Hour"
}

export enum AgentSummaryReportExtensionType {
  EX = "Extensions",
  DDI = "DDIs",
  HD = "Hot Desk Users",
}

export interface IEmailSubReport {
  reportTitle?: string;
  reportID?: string;
  reportType: string; // should be picker of EmailReportType, default is EmailReportType.agentSummary
}

export interface IEmailSubReportAgentSummary extends IEmailSubReport {
  ddisToExclude: string[]; // phone numbers to exclude from the report, default[]
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  extensionsToExclude: string[]; // the names of extensions to exclude, default []
  extensionType: AgentSummaryReportExtensionType | string; // what we use for organising the report, default AgentSummaryReportExtensionType.EX
  hourly: boolean; // indicates if the report should be broken in to separate hours, default false
  includeMissed: boolean; // should we show missed calls on the report, default false
  minimumWaitTime: number; // minimum number of seconds of ringing for a call to be considered missed, default 5
  onlyShowTotals: boolean; // indicates that we only want to see the totals for the day on the report, default false
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:00
  useQueueLogs: boolean; // whether to use CDRs or the queue logs, default false
  agentSummaryOnlyShowInternalCalls: boolean; // determines if we are only showing internal calls on this report
  agentSummarySortBy: string;
  agentSummaryHideAgentsThatAnsweredNoCalls: boolean;
  agentSummaryAddQueueStats:boolean
  agentSummaryExtensionsToInclude:string[]
  agentSummaryIncomingOnly:boolean
  useSMDR:boolean
}

export interface IEmailSubReportAutoAttendantOptions extends IEmailSubReport {
  aaShowIndividualCalls: boolean; // indicates if the individual calls should be shown
  endTime: string; // time the report ends at, should be HH:MM:SS
  startTime: string; // time the report starts at, should be HH:MM:SS
}

export interface IEmailSubReportCallLogs extends IEmailSubReport {
  //made
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:00
  callLogsShowPhoneBookNames: boolean; // do we want phone book names in the report, default false
  callLogsShowTotalsByNumber: boolean; // do we want to see totals on the report, default false
  callLogsStatuses: string; //which statuses do we want to filter on
  callLogsDirection: string; //which direction we want
  callLogsSortBy:string;
  callLogsCallerIDsAllowed:string[];
  callLogsDDIsToInclude:string[];
  callLogsTTASeconds:number;
  callLogsJustShowFrequentCallers:boolean;
  callLogsFrequentCallersTimePeriodDays:number;
  callLogsFrequentCallersMinimumCalls:number;
  callLogsExtensionsToInclude:string[]
}

export interface IEmailSubReportCallPaths extends IEmailSubReport {
  //made
  callPathsIncludeOutgoing: boolean; // should outgoing calls be on the report, default false
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:00
  callPathsShowFullExtensionName: boolean //show the full name of the extension on the report
}

export interface IEmailSubReportCallRecordings extends IEmailSubReport {
  callRecordingsMinimumDurationSeconds: number; // minimum duration of a recording for it to appear on the report
  endTime: string; // time the report ends at, should be HH:MM:SS
  startTime: string; // time the report starts at, should be HH:MM:SS
  callRecordingsExtensionsToInclude:string[];
  callRecordingsSortBy:string;
}

export interface IEmailSubReportDNDChanges extends IEmailSubReport {
  endTime: string; // the time the report ends at, HH:MM:SS, default 18:00:00
  startTime: string; // time the report start at, should be HH:MM:SS, default 08:00:00
}

export interface IEmailSubReportPerDDIPerHour extends IEmailSubReport {
  perDDIPerHourDDIsToInclude:string[]
  perDDIPerHourSummaryFormat:boolean
}

export interface IEmailSubReportCustomPresenceChanges extends IEmailSubReport {
  endTime: string; // the time the report ends at, HH:MM:SS, default 18:00:00
  startTime: string; // time the report start at, should be HH:MM:SS, default 08:00:00
  customPresenceShowOnlyHotDesk:boolean; //do we only show hot desk agents on the report?
  customPresenceHideLoggedOut:boolean //hide agents who did not log in during the report times
  customPresenceExtensionsToInclude:string[]
}

export interface IEmailSubReportHotDeskLogins extends IEmailSubReport {
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:0
  hotDeskLoginsHideNotLoggedIn:boolean; //indicates who to hide on the report
}

export enum LostCallsReportRange {
  Day = "Day",
  Week = "Week",
}

export interface IEmailSubReportLostCalls extends IEmailSubReport {
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  lostCallsAgentsToInclude: string[]
  lostCallsAgentsToExclude: string[]; // numbers of agents to exclude from the hourly performance report
  lostCallsQueuesToInclude: string[]
  lostCallsQueuesToExclude: string[]; // names of queues to exclude from the report, default []
  lostCallsMinimumWaitTime: number; // minimum number of seconds a call must ring for before being classed as missed, default 5
  lostCallsOnlyShowTotals: boolean; // should we only show the totals on the report, default false
  lostCallsReportRange: LostCallsReportRange | string; // are we running for a week or a day? default Day
  lostCallsShowIndividualMissedCalls: boolean; // shows the missed calls in a list at the end of the report, default false
  lostCallsUseCDRs: boolean; // if the customer is not using call queues this must be ticked, default false
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:00
  lostCallsAgentHourlyCallThreshold: number; //number of calls that an agent should take in an hour
  lostCallsShowAgentHourlyPerformance: boolean; //whether or not we should show the hourly performance
  lostCallsShowAgentHourlyPerformanceHideNotLoggedIn:boolean //whether or not we show agents that have not logged in on the report
  lostCallsShowAgentHourlyPerformanceHideNoCalls:boolean //hide agents who didn't answer any calls during the report
}

export interface IEmailSubReportNumberTrackers extends IEmailSubReport {
  dndChangesExtensionsToExclude: string[]; // extension names to exclude from the report, default []
  endTime: string; // time the report ends at, should be HH:MM:SS, default 18:00:00
  startTime: string; // time the report starts at, should be HH:MM:SS, default 08:00:00
}

export enum EmailReportRange {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}

interface IEmailReportLastSentEmailAddress {
  emailAddress:string
  datetime:string
}

export interface IEmailReport {
  lastSentEmailAddresses:IEmailReportLastSentEmailAddress[]
  fromEmailAddress: string // the address this report will appear to come from if reseller is set up using mailgun
  dontSendHTML:boolean //dont send the HTML content in the email
  attachCSV: boolean;
  attachJSON: boolean;
  attachPDF: boolean;
  customersSitesServicesId: string;
  emailAddresses: string[];
  id: string; // unique id, generated by the server
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  range: string | EmailReportRange; // should a picker of Day, Week, Month, default Day
  name: string; // free text, ends up in the subject of the email, default "New Report"
  reportTime: string; // when the email is sent, format is HH:MM:SS, default 09:00:00
  subReports: IEmailSubReport[];
}

export interface IEmailReportTest extends IEmailReport {
  manualStartDateTime: string; //2020-10-09T01:12:34
  manualEndDateTime: string; //2020-10-11T01:12:34
  manualDateTimeZoneMinutesOffset: number; //-60 when it is BST, e.g the browser is 60 minutes ahead
  showHTML:boolean; //if we should be showing the result in the page
}

export interface IEmailReportsHttpResponse {
  items: IEmailReport[];
}

export interface IEmailReportHttpResponse {
  item: IEmailReport;
}
