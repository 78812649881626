import * as React from "react";

import { IEmailSubReportPerDDIPerHour } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import MuiFreeTypeStringList from "../MuiComponents/MuiFreeTypeStringList";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";


export interface IEmailSubReportPerDDIPerHourProps {
  data: IEmailSubReportPerDDIPerHour;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportPerDDIPerHour:React.FunctionComponent<IEmailSubReportPerDDIPerHourProps> = (props:IEmailSubReportPerDDIPerHourProps) => {
  return <Grid container>
    <Grid item xs={6}>
        <MuiTitle title="DDIs To Include (leave empty to include all)" />

        <MuiFreeTypeStringList
          field="perDDIPerHourDDIsToInclude"
          value={props.data.perDDIPerHourDDIsToInclude}
          updateField={props.submitFunction}
          text="DDIs"
          placeholder=""
        />
      </Grid>
      <Grid item xs={6}>
        <MuiTitle title="Horizontal summary format?" />
        <MuiToggleBoolControlled value={props.data.perDDIPerHourSummaryFormat} setValue={() => {
          props.submitFunction('perDDIPerHourSummaryFormat',!props.data.perDDIPerHourSummaryFormat)
        }} />
      </Grid>
  </Grid>
}
export default EmailReportPerDDIPerHour
